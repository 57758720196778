<template>
  <v-card flat>
    <v-card-text>
      <v-tabs v-model="tab2" @change="tab_change" class="my-tabs">
        <v-tabs-slider></v-tabs-slider>
        <v-tab key="1" href="#1">
          APM
          <v-badge
            :color="'purple'"
            class="mb-1 ml-1"
            v-if="tests_list.filter((elm) => elm.testtype_id == 1).length > 0"
          >
            <template v-slot:badge>
              {{ tests_list.filter((elm) => elm.testtype_id == 1).length }}
            </template>
          </v-badge>
        </v-tab>
        <v-tab key="3" href="#3">
          Xtree
          <v-badge
            :color="'purple'"
            class="mb-1 ml-1"
            v-if="tests_list.filter((elm) => elm.testtype_id == 3).length > 0"
          >
            <template v-slot:badge>
              {{ tests_list.filter((elm) => elm.testtype_id == 3).length }}
            </template>
          </v-badge>
        </v-tab>
        <v-tab key="5" href="#5">
          Wellhead
          <v-badge
            :color="'purple'"
            class="mb-1 ml-1"
            v-if="tests_list.filter((elm) => elm.testtype_id == 5).length > 0"
          >
            <template v-slot:badge>
              {{ tests_list.filter((elm) => elm.testtype_id == 5).length }}
            </template>
          </v-badge>
        </v-tab>
        <v-tab key="6" href="#6">
          Downhole
          <v-badge
            :color="'purple'"
            class="mb-1 ml-1"
            v-if="tests_list.filter((elm) => elm.testtype_id == 6).length > 0"
          >
            <template v-slot:badge>
              {{ tests_list.filter((elm) => elm.testtype_id == 6).length }}
            </template>
          </v-badge>
        </v-tab>
        <v-tab key="7" href="#7">
          DHSV
          <v-badge
            :color="'purple'"
            class="mb-1 ml-1"
            v-if="tests_list.filter((elm) => elm.testtype_id == 7).length > 0"
          >
            <template v-slot:badge>
              {{ tests_list.filter((elm) => elm.testtype_id == 7).length }}
            </template>
          </v-badge>
        </v-tab>
        <v-tab key="8" href="#8">
          Leak detection
          <v-badge
            :color="'purple'"
            class="mb-1 ml-1"
            v-if="tests_list.filter((elm) => elm.testtype_id == 8).length > 0"
          >
            <template v-slot:badge>
              {{ tests_list.filter((elm) => elm.testtype_id == 8).length }}
            </template>
          </v-badge>
        </v-tab>
        <v-tab key="4" href="#4">
          BO/BU
          <v-badge
            :color="'purple'"
            class="mb-1 ml-1"
            v-if="tests_list.filter((elm) => elm.testtype_id == 4).length > 0"
          >
            <template v-slot:badge>
              {{ tests_list.filter((elm) => elm.testtype_id == 4).length }}
            </template>
          </v-badge>
        </v-tab>
        <v-tab key="10" href="#10">
          Finger Print
          <v-badge
            :color="'purple'"
            class="mb-1 ml-1"
            v-if="fingers_list.length > 0"
          >
            <template v-slot:badge>
              {{ fingers_list.length }}
            </template>
          </v-badge>
        </v-tab>
        <v-tab key="2" href="#2">
          Corrosion log
          <v-badge
            :color="'purple'"
            class="mb-1 ml-1"
            v-if="tests_list.filter((elm) => elm.testtype_id == 2).length > 0"
          >
            <template v-slot:badge>
              {{ tests_list.filter((elm) => elm.testtype_id == 2).length }}
            </template>
          </v-badge>
        </v-tab>
        <v-tab key="9" href="#9">
          Cement log
          <v-badge
            :color="'purple'"
            class="mb-1 ml-1"
            v-if="tests_list.filter((elm) => elm.testtype_id == 9).length > 0"
          >
            <template v-slot:badge>
              {{ tests_list.filter((elm) => elm.testtype_id == 9).length }}
            </template>
          </v-badge>
        </v-tab>
        <v-tabs-items v-model="tab2">
          <v-tab-item :value="'1'">
            <v-container>
              <v-tabs v-model="tab3">
                <v-tab key="1" href="#1"> Chart </v-tab>
                <v-tab key="2" href="#2"> Data </v-tab>
              </v-tabs>
              <v-tabs-items v-model="tab3">
                <v-tab-item :value="'1'">
                  <v-card>
                    <v-card-text>
                      <chartform
                        :well_id="parseInt(well.id)"
                        :tubulars_list="tubulars_list"
                        :chart_id="chart_id"
                        :key="kchart"
                      ></chartform>
                    </v-card-text>
                  </v-card>
                </v-tab-item>
                <v-tab-item :value="'2'">
                  <listitems
                    :list="tests_list.filter((elm) => elm.testtype_id == 1)"
                    :title="'APM Tests'"
                    :headers="apm_headers"
                    :showedit="false"
                    :master="true"
                    :add="true"
                    :del="false"
                    :key="apm_key"
                    @open="test_open"
                    @rowselect="TestSelect"
                    :ipg="10"
                    @col_btn1_click="test_open"
                    @col_btn2_click="TestDelete"
                    @show_chart="show_chart"
                    :selitem="test"
                    :exp_excel="true"
                    :laoding="data_loading"
                  >
                  </listitems>
                </v-tab-item>
              </v-tabs-items>
            </v-container>
          </v-tab-item>
          <v-tab-item :value="'4'">
            <listitems
              :list="tests_list.filter((elm) => elm.testtype_id == 4)"
              :title="'Bleed Off Tests'"
              :headers="apm_headers"
              :showedit="false"
              :master="true"
              :add="true"
              :del="false"
              :key="bo_key"
              @open="test_open"
              @rowselect="TestSelect"
              :ipg="10"
              @col_btn1_click="test_open"
              @col_btn2_click="TestDelete"
              :selitem="test"
              :exp_excel="true"
              :laoding="data_loading"
            >
            </listitems>
          </v-tab-item>
          <v-tab-item :value="'10'">
            <v-card flat>
              <v-card-text>
                <v-row>
                  <v-col cols="12" sm="12" md="12">
                    <listitems
                      :list="fingers_list"
                      :title="'Analyses'"
                      :headers="fingers_headers"
                      :showedit="false"
                      :master="true"
                      :add="true"
                      :del="false"
                      :key="fng_key"
                      @open="FingerOpen"
                      @rowselect="FingerSelect"
                      :ipg="10"
                      @col_btn1_click="FingerOpen"
                      @col_btn2_click="FingerDelete"
                      :selitem="finger"
                      :exp_excel="true"
                      :laoding="data_loading"
                    >
                    </listitems>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="'2'">
            <v-card flat>
              <v-card-title>
                <v-tabs v-model="tab11" @change="tab11_change">
                  <v-tab key="1" href="#1"> Actual Corrosion </v-tab>
                  <v-tab key="2" href="#2">Tests</v-tab>
                </v-tabs>
              </v-card-title>
              <v-card-text>
                <v-tabs-items v-model="tab11">
                  <v-tab-item :value="'1'">
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <listitems
                          :list="tubulars_list"
                          :title="'Casings'"
                          :headers="tubulars_headers2"
                          :showstd="false"
                          :showedit="false"
                          :master="true"
                          :key="cas_key"
                          @rowselect="TubularSelect"
                          :ipg="-1"
                          :hdf="true"
                          :add="false"
                          :del="false"
                        >
                        </listitems>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" v-if="tubular.id">
                        <v-tabs v-model="tab12">
                          <v-tab key="1" href="#1"> Corrosion </v-tab>
                          <v-tab key="2" href="#2">Log Ranges</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab12">
                          <v-tab-item :value="'1'">
                            <listitems
                              :list="tubular.corrosionRanges"
                              :title="'Corrosion'"
                              :headers="corrosions_headers2"
                              :showstd="false"
                              :showedit="false"
                              :master="false"
                              :key="tc_key"
                              :ipg="-1"
                              :hdf="true"
                              :add="false"
                              :del="false"
                              :laoding="cor_loading"
                            >
                            </listitems>
                          </v-tab-item>
                          <v-tab-item :value="'2'">
                            <listitems
                              :list="tubular.logRanges"
                              :title="'Log Ranges'"
                              :headers="logs_headers"
                              :showstd="false"
                              :showedit="false"
                              :master="false"
                              :key="tc_key"
                              :ipg="-1"
                              :kid="'welltest_id'"
                              :hdf="true"
                              :add="false"
                              :del="false"
                              :laoding="cor_loading"
                            >
                            </listitems>
                          </v-tab-item>
                        </v-tabs-items>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item :value="'2'">
                    <v-row>
                      <v-col cols="12" sm="12" md="12">
                        <listitems
                          :list="
                            tests_list.filter((elm) => elm.testtype_id == 2)
                          "
                          :title="'Corrosion Test'"
                          :headers="test_headers"
                          :showstd="false"
                          :showedit="false"
                          :master="true"
                          :add="true"
                          :add_disable="
                            tests_list
                              .filter((elm) => elm.testtype_id == 2)
                              .findIndex((elm) => elm.statut_id < 6) == -1
                          "
                          :del="false"
                          :key="cor_key"
                          :ipg="-1"
                          @open="test_open"
                          @col_btn2_click="TestDelete"
                          @rowselect="CorrosionSelect"
                          @filtred="corrosionfiltred"
                          :hdf="true"
                        >
                        </listitems>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item :value="'9'">
            <v-card flat>
              <v-card-title>
                <v-tabs v-model="tab11" @change="tab11_change">
                  <v-tab key="1" href="#1"> Actual Cement </v-tab>
                  <v-tab key="2" href="#2">Tests</v-tab>
                </v-tabs>
              </v-card-title>
              <v-card-text>
                <v-tabs-items v-model="tab11">
                  <v-tab-item :value="'1'">
                    <v-row>
                      <v-col cols="12" sm="6" md="6">
                        <listitems
                          :list="tubulars_list"
                          :title="'Casings'"
                          :headers="tubulars_headers2"
                          :showstd="false"
                          :showedit="false"
                          :master="true"
                          :key="cas_key"
                          @rowselect="TubularSelect"
                          :ipg="-1"
                          :hdf="true"
                          :add="false"
                          :del="false"
                        >
                        </listitems>
                      </v-col>
                      <v-col cols="12" sm="6" md="6" v-if="tubular.id">
                        <v-tabs v-model="tab12">
                          <v-tab key="1" href="#1"> Cement </v-tab>
                          <v-tab key="2" href="#2">Log Ranges</v-tab>
                        </v-tabs>
                        <v-tabs-items v-model="tab12">
                          <v-tab-item :value="'1'">
                            <listitems
                              :list="tubular.cementRanges"
                              :title="'Cement'"
                              :headers="cements_headers2"
                              :showstd="false"
                              :showedit="false"
                              :master="false"
                              :key="tc_key"
                              :ipg="-1"
                              :hdf="true"
                              :add="false"
                              :del="false"
                              :laoding="cor_loading"
                            >
                            </listitems>
                          </v-tab-item>
                          <v-tab-item :value="'2'">
                            <listitems
                              :list="tubular.logRanges"
                              :title="'Log Ranges'"
                              :headers="logs_headers"
                              :showstd="false"
                              :showedit="false"
                              :master="false"
                              :key="tc_key"
                              :ipg="-1"
                              :kid="'welltest_id'"
                              :hdf="true"
                              :add="false"
                              :del="false"
                              :laoding="cor_loading"
                            >
                            </listitems>
                          </v-tab-item>
                        </v-tabs-items>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item :value="'2'">
                    <v-row>
                      <v-col cols="12" sm="6" md="12">
                        <listitems
                          :list="
                            tests_list.filter((elm) => elm.testtype_id == 9)
                          "
                          :title="'Cements Test'"
                          :headers="test_headers"
                          :Get_suf="'WellsCement'"
                          :showstd="false"
                          :showedit="false"
                          :master="true"
                          :add="true"
                          :add_disable="
                            tests_list
                              .filter((elm) => elm.testtype_id == 9)
                              .findIndex((elm) => elm.statut_id < 6) == -1
                          "
                          :del="false"
                          :key="cem_key"
                          :ipg="-1"
                          @open="CementOpen"
                          @col_btn2_click="TestDelete"
                          @rowselect="CementSelect"
                          @filtred="cementfiltred"
                          :hdf="true"
                        >
                        </listitems>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                </v-tabs-items>
              </v-card-text> </v-card
          ></v-tab-item>
          <v-tab-item :value="'8'">
            <listitems
              :list="tests_list.filter((elm) => elm.testtype_id == 8)"
              :title="'LEAK Tests'"
              :headers="apm_headers"
              :showedit="false"
              :master="true"
              :add="true"
              :del="false"
              :key="leak_key"
              @open="test_open"
              @rowselect="TestSelect"
              :ipg="10"
              @col_btn1_click="test_open"
              @col_btn2_click="TestDelete"
              :selitem="test"
              :exp_excel="true"
              :laoding="data_loading"
            >
            </listitems>
          </v-tab-item>
          <v-tab-item :value="'3'">
            <listitems
              :list="tests_list.filter((elm) => elm.testtype_id == 3)"
              :title="'Xtree Tests'"
              :headers="apm_headers"
              :showedit="false"
              :master="true"
              :add="true"
              :del="false"
              :key="xtree_key"
              @open="test_open"
              @rowselect="TestSelect"
              :ipg="10"
              @col_btn1_click="test_open"
              @col_btn2_click="TestDelete"
              :selitem="test"
              :exp_excel="true"
              :laoding="data_loading"
            >
            </listitems>
          </v-tab-item>
          <v-tab-item :value="'5'">
            <listitems
              :list="tests_list.filter((elm) => elm.testtype_id == 5)"
              :title="'Wellhead Tests'"
              :headers="apm_headers"
              :showedit="false"
              :master="true"
              :add="true"
              :del="false"
              :key="head_key"
              @open="test_open"
              @rowselect="TestSelect"
              :ipg="10"
              @col_btn1_click="test_open"
              @col_btn2_click="TestDelete"
              :selitem="test"
              :exp_excel="true"
              :laoding="data_loading"
            >
            </listitems
          ></v-tab-item>
          <v-tab-item :value="'6'">
            <listitems
              :list="tests_list.filter((elm) => elm.testtype_id == 6)"
              :title="'Downhole Tests'"
              :headers="apm_headers"
              :showedit="false"
              :master="true"
              :add="true"
              :del="false"
              :key="down_key"
              @open="test_open"
              @rowselect="TestSelect"
              :ipg="10"
              @col_btn1_click="test_open"
              @col_btn2_click="TestDelete"
              :selitem="test"
              :exp_excel="true"
              :laoding="data_loading"
            >
            </listitems>
          </v-tab-item>
          <v-tab-item :value="'7'">
            <listitems
              :list="tests_list.filter((elm) => elm.testtype_id == 7)"
              :title="'DHSV Tests'"
              :headers="apm_headers"
              :showedit="false"
              :master="true"
              :add="true"
              :del="false"
              :key="dhsv_key"
              @open="test_open"
              @rowselect="TestSelect"
              :ipg="10"
              @col_btn1_click="test_open"
              @col_btn2_click="TestDelete"
              :selitem="test"
              :exp_excel="true"
              :laoding="data_loading"
            >
            </listitems>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card-text>
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbar_timeout"
      top
      :color="snackbar_color"
    >
      {{ snackbar_text }}
    </v-snackbar>
    <Corrosion_Test
      :showForm="!isCorrosionClose"
      :tool_list="
        tool_list.filter((elm) => elm.ctl_type == 0 || elm.ctl_type == 2)
      "
      :level_list="level_list_cor"
      :depth_unit="$store.state.options[0].depth"
      :last_corrosion="last_corrosion"
      :tubulars_list="tubulars_list"
      :actual_corrosions="actual_corrosions"
      :defaults_list="defaults_list"
      :item="corrosion_test"
      :user_list="users"
      :well_list="[well]"
      :key="kcor"
      :editer="editer"
      :valider="valider"
      @close="isCorrosionClose = true"
      @change="well_change"
    ></Corrosion_Test>
    <Cement_Test
      :showForm="!isCementClose"
      :tool_list="
        tool_list.filter((elm) => elm.ctl_type == 1 || elm.ctl_type == 2)
      "
      :level_list="level_list_cem"
      :well_list="[well]"
      :depth_unit="$store.state.options[0].depth"
      :tubulars_list="tubulars_list"
      :actual_cements="actual_cements"
      :item="cement_test"
      :user_list="users"
      :key="kcem"
      :editer="editer"
      :valider="valider"
      @close="isCementClose = true"
      @change="well_change"
    ></Cement_Test>
    <fingerform
      :showform="!isFingerClose"
      :modify="well.statut_id == 1"
      :item="finger"
      :well="well"
      @close="isFingerClose = true"
      :fluids="fluids"
      :fluids_elements="fluids_elements"
      :wellsformations="formations_list.filter((elm) => elm.depth > 0)"
      :wellstubulars="
        tubulars_list.filter(
          (elm) => elm.depth > 0 && elm.depthfrom == 0 && elm.tubular_id != 1
        )
      "
      :user_list="users"
      :editer="editer"
      :valider="valider"
      @add="well_change"
      @change="well_change"
      :key="fingerkey"
    ></fingerform>

    <testform
      :showForm="!isTestClose"
      :tests_list="tests_list"
      :well_list="[well]"
      :founds="founds"
      :failures="test_failures"
      :all_failures="all_failures"
      :barriers="barriers"
      :teststypes_list="teststypes_list"
      :tubulars_list="tubulars_list"
      :apms="apms"
      :downs="downs"
      :xtrees="xtrees"
      :testdhsv="dhsvs"
      :test_bo="bo_bu"
      :item="test"
      :user_list="users"
      :key="ktest"
      :editer="editer"
      :valider="valider"
      :testtype_id="parseInt(tab2)"
      :tab_label="tab_label"
      @close="isTestClose = true"
      @update="well_change"
    ></testform>

    <addform
      :showForm="!isAddClose"
      :well_id="parseInt(well.id)"
      :testtype_id="parseInt(tab2)"
      @close="isAddClose = true"
      :key="kadd"
      :teststypes_list="teststypes_list"
      @update="well_change"
    ></addform>
    <template>
      <confirmdialog ref="confirm" />
    </template>
  </v-card>
</template>

<script>
import DELETE_TEST from "../graphql/WellTest/CCL/DELETE_TEST.gql";
import DELETE_FP from "../graphql/FingerPrint/DELETE_FP.gql";
import allquery from "../graphql/WellTest/TestForm/allQuery.gql";
import TESTQUERY from "../graphql/WellTest/TestForm/testQuery.gql";
export default {
  components: {
    Corrosion_Test: () => import("../components/Corrosion_Test.vue"),
    Cement_Test: () => import("../components/Cement_Test.vue"),
    fingerform: () => import("../components/FingerForm.vue"),
    listitems: () => import("../components/ListItems.vue"),
    confirmdialog: () => import("../components/ConfirmDialog.vue"),
    testform: () => import("../components/Test_Form.vue"),
    chartform: () => import("../components/Chart_Form.vue"),
    addform: () => import("../components/Test_Add.vue"),
  },
  props: {
    well: Object,
    tool_list: Array,
    level_list_cor: Array,
    level_list_cem: Array,
    users: Array,
    fluids_elements: Array,
    fluids: Array,
    tubulars: Array,
    apicasing: Array,
    teststypes_list: Array,
    defaults_list: Array,
    chart_id: String,
    old_tab: String,
    actual_corrosions: Array,
    actual_cements: Array,
  },
  data() {
    return {
      snackbar: false,
      snackbar_timeout: 2000,
      snackbar_text: "",
      snackbar_color: "primary",
      modify: false,
      data_loading: false,
      tab2: 1,
      tab3: 1,
      tkey: 300,

      apm_key: 1800,
      bo_key: 18000,
      dhsv_key: 19000,
      xtree_key: 20000,
      down_key: 21000,
      head_key: 21000,

      annulus: false,
      cem_key: 850,
      leak_key: 950,
      tab11: "1",
      tab12: "1",
      kcor: 500,
      kcem: 550,
      fingerkey: 2050,
      tab_label: "Annuli pressure",
      isFingerClose: true,
      isCorrosionClose: true,
      isCementClose: true,
      selitem: {},
      finger: {},
      test: {},
      tubulars_list: [],
      annulus_list: [],
      tests_list: [],
      cements_list: [],
      corrosions_list: [],

      fingers_list: [],
      cor_key: 1350,
      fng_key: 1850,
      cas_key: 1500,
      tc_key: 1600,
      cement_test: {},
      corrosion_test: {},
      last_corrosion: [],
      formations_list: [],
      corrosions_headers2: [
        {
          text: "Depth From",
          value: "depthfrom",
          selected: true,
          slot: "cur33",
          align: "end",
        },
        {
          text: "Depth To",
          value: "depthto",
          selected: true,
          slot: "cur33",
          align: "end",
        },
        {
          text: "Depth",
          value: "depth",
          selected: true,
          slot: "cur33",
          align: "end",
        },
        {
          text: "Evaluation",
          value: "evaluation",
          slot: "progress",
          selected: true,
          color: "color",
        },
        {
          text: "Level",
          value: "level",
          selected: true,
        },
        {
          text: "Default",
          value: "default_name",
          selected: true,
        },
      ],
      cements_headers2: [
        {
          text: "Depth From",
          value: "depthfrom",
          selected: true,
          slot: "cur33",
          align: "end",
        },
        {
          text: "Depth To",
          value: "depthto",
          selected: true,
          slot: "cur33",
          align: "end",
        },
        {
          text: "Depth",
          value: "depth",
          selected: true,
          slot: "cur33",
          align: "end",
        },

        {
          text: "Evaluation",
          value: "level",
          slot: "chip",
          color: "color",
          selected: true,
        },
      ],
      logs_headers: [
        {
          text: "No",
          value: "no",
          selected: true,
        },

        {
          text: "Log From",
          value: "logfrom",
          selected: true,
          slot: "cur33",
          align: "end",
          import: true,
        },
        {
          text: "Log To",
          value: "logto",
          selected: true,
          slot: "cur33",
          align: "end",
          import: true,
        },
        {
          text: "Test Date",
          value: "testdate",
          selected: true,
          slot: "date",
        },
      ],
      test_type: -1,
      test_type_id: null,
      test_list: [],
      founds: [],
      test_failures: [],
      all_failures: [],
      barriers: [],

      apms: [],
      bo_bu: [],
      xtrees: [],
      downs: [],
      dhsvs: {},
      editer: false,
      valider: false,
      isTestClose: true,
      isAPMClose: true,
      isLEAKClose: true,
      isBOClose: true,
      isDHSVClose: true,
      isXTClose: true,
      isWHClose: true,
      isDownClose: true,
      isAddClose: true,
      ktest: 90000,
      kAPM: 10000,
      kLEAK: 70000,
      kBO: 20000,
      kXT: 30000,
      kWH: 40000,
      kDHSV: 50000,
      kDown: 60000,
      kadd: 80000,
      kchart: 7800,

      test_headers: [
        {
          text: "Code",
          value: "code",
          slot: "href",
          selected: true,
        },
        {
          text: "Tool",
          value: "toolname",
          selected: true,
        },
        {
          text: "Statut",
          value: "status",
          selected: true,
        },
        {
          text: "Date",
          value: "testdate",
          selected: true,
        },
        {
          text: "Tested by",
          value: "operatorname",
          selected: true,
        },
        {
          text: "Interpreted by",
          value: "interpretedname",
          enum: "INTERPRETEDNAME",
          selected: false,
        },
        {
          text: "Interpreted at",
          value: "interpreted_at",
          selected: false,
        },
        {
          text: "Data entred by",
          value: "editedname",
          selected: false,
        },
        {
          text: "Entred at",
          value: "created_at",
          selected: false,
        },
        {
          text: "Attachments",
          value: "nbdocs",
          slot: "col_btn5",
          selected: true,
          icon: "mdi-attachment",
          tooltip: "Attached Documents",
          sortable: false,
          width: "80",
        },
        {
          text: "Delete",
          slot: "col_btn2",
          selected: true,
          icon: "mdi-delete",
          width: "10",
          column1: "statut_id",
          valeur1: "4",
          sortable: false,
        },
      ],

      fingers_headers: [
        {
          text: "No",
          value: "no",
          selected: true,
        },

        {
          text: "Code",
          value: "code",
          slot: "href100",
          column: "statut_id",
          valeur: 1,
          selected: true,
        },
        {
          text: "Annulus",
          value: "annulus",
          selected: true,
        },
        {
          text: "Fluid",
          value: "fluid_label",
          selected: true,
        },
        {
          text: "Date",
          value: "test_date",
          slot: "date",
          selected: true,
        },
        {
          text: "By",
          value: "agent_name",
          selected: false,
        },
        {
          text: "Status",
          value: "status",
          selected: true,
        },
        {
          text: "Update",
          slot: "col_btn1",
          selected: true,
          icon: "mdi-pencil",
          width: "10",
          sortable: false,
        },
        {
          text: "Delete",
          slot: "col_btn2",
          selected: true,
          icon: "mdi-delete",
          width: "10",
          sortable: false,
        },
        {
          text: "Attachments",
          value: "nbdocs",
          slot: "col_btn5",
          selected: true,
          icon: "mdi-attachment",
          tooltip: "Attached Documents",
          sortable: false,
          width: "80",
        },
      ],
      apm_headers: [
        {
          text: "Code",
          value: "code",
          slot: "href",
          selected: true,
        },

        {
          text: "Statut",
          value: "status",
          selected: true,
        },
        {
          text: "Date",
          value: "testdate",
          selected: true,
        },
        {
          text: "Tested by",
          value: "operatorname",
          selected: true,
        },
        {
          text: "Attachments",
          value: "nbdocs",
          slot: "col_btn5",
          selected: true,
          icon: "mdi-attachment",
          tooltip: "Attached Documents",
          sortable: false,
          width: "80",
        },
        {
          text: "Delete",
          slot: "col_btn2",
          selected: true,
          icon: "mdi-delete",
          width: "10",
          column1: "statut_id",
          valeur1: "4",
          sortable: false,
        },
      ],
      tubular: {},
      tubulars_headers2: [
        {
          text: "Position",
          value: "no",
          selected: true,
          sortable: false,
          index: 0,
        },

        {
          text: "Item",
          value: "item",
          selected: true,
          sortable: false,
          index: 0,
        },
        {
          text: "Size",
          value: "api_in",
          selected: true,
          slot: "text_icon",
          icon: "mdi-dots-horizontal",
          column: "nbsizes",
          valeur: "1",
          width: "250",
          sortable: false,
          index: 0,
        },
        {
          text: "Age (Year)",
          value: "age",
          selected: true,
          sortable: false,
          index: 0,
        },
        {
          text: "# issues",
          value: "nbcorrosions",
          align: "end",
          slot: "cur",
          dec: 0,
          selected: true,
          sortable: false,
          index: 0,
        },
      ],
      cor_loading: false,
    };
  },
  mounted() {
    if (this.old_tab) this.tab2 = this.old_tab;
    this.editer =
      this.$store.state.auth.includes("02003") || this.$store.state.isadmin;
    this.valider =
      this.$store.state.auth.includes("03003") || this.$store.state.isadmin;
    this.init_list();

    this.kchart++;
  },
  computed: {
    tests_selected() {
      return this.tests_list.filter((x) => x.testtype_id == this.test_type);
    },
    sizes_list() {
      let l = [];
      this.tubulars_list.forEach((tubular) => {
        tubular.sizes_list.forEach((size) => {
          l.push(size);
        });
      });
      return l;
    },
    add_possible() {
      let l = this.tests_list.filter(
        (x) =>
          x.testtype_id == this.tab2 &&
          x.planning_id == this.$store.state.planning[0].id
      );
      return l.length == 0;
    },
  },
  watch: {
    "well.wellstests": {
      handler() {
        this.init_list();
      },
    },
  },
  methods: {
    snack(t) {
      this.snackbar = true;
      this.snackbar_color = "error";
      this.snackbar_text = t;
    },
    sortBy() {
      return function (a, b) {
        if (parseFloat(a) < parseFloat(b)) return 1;
        if (parseFloat(a) > parseFloat(b)) return -1;
        return 0;
      };
    },
    init_last_corrosion() {
      this.tubulars_list.forEach((element) => {
        element.corrosionRanges = [];
        let i = this.actual_corrosions.findIndex(
          (elm) => elm.well_id == this.well.id
        );
        if (i >= 0) {
          let j = this.actual_corrosions[i].tubulars.findIndex(
            (elm) => elm.well_tubular_id == element.id
          );
          if (j >= 0) {
            this.actual_corrosions[i].tubulars[j].item = element.item;
            element.logRanges = this.actual_corrosions[i].tubulars[j].logRanges;
            element.nbcorrosions =
              this.actual_corrosions[i].tubulars[j].corrosionRanges.length;
            element.corrosionRanges =
              this.actual_corrosions[i].tubulars[j].corrosionRanges;
          }
        }
      });
      this.cas_key++;
    },

    init_last_cement() {
      this.tubulars_list.forEach((element) => {
        element.cementRanges = [];
        let i = this.actual_cements.findIndex(
          (elm) => elm.well_id == this.well.id
        );
        if (i >= 0) {
          let j = this.actual_cements[i].tubulars.findIndex(
            (elm) => elm.well_tubular_id == element.id
          );
          if (j >= 0) {
            this.actual_cements[i].tubulars[j].item = element.item;
            element.CementlogRanges =
              this.actual_cements[i].tubulars[j].logRanges;
            element.nbcements =
              this.actual_cements[i].tubulars[j].cementRanges.length;
            element.cementRanges =
              this.actual_cements[i].tubulars[j].cementRanges;
          }
        }
      });
      this.cas_key++;
    },

    async tab_change() {
      if (this.tab2 == 2 || this.tab2 == 9) {
        this.cor_loading = true;
        this.init_last_corrosion();
        this.init_last_cement();
        this.cor_loading = false;
      }

      this.$emit("tab_change", this.tab2);
    },
    tab11_change() {
      this.$emit("tab11_change", this.tab11);
    },
    cementfiltred(list) {
      if (list) this.cements_list = list;
    },
    corrosionfiltred(list) {
      if (list) this.corrosions_list = list;
    },

    CementSelect(item) {
      this.cement_test = item;
      this.$emit("cement_test", this.cement_test);
      this.cements_list = [];
      if (item.statut_id == 6)
        this.cements_list = this.cement_test.cement_details;
      this.ksvg1++;
    },

    CorrosionSelect(item) {
      this.corrosion_test = item;
      this.$emit("corrosion_test", this.corrosion_test);
      this.corrosions_list = [];
      if (item.statut_id == 6)
        this.corrosions_list = this.corrosion_test.corrosion_details;
      this.ksvg1++;
    },
    well_change() {
      //  this.loadTest();
      this.$emit("well_change");
      this.kchart++;
    },
    async FingerDelete(item) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Delete Test " + item.fluid_label + " !!-Are you sur ?",
          { color: "orange darken-3" }
        )
      ) {
        this.data_loading = true;
        let r = await this.$maj(DELETE_FP, { id: item.id });
        if (r) {
          this.well_change();
          this.fng_key++;
        }
        this.data_loading = false;
      }
    },
    show_chart() {
      this.kchart++;
    },
    async TestDelete(item) {
      if (
        await this.$refs.confirm.open(
          "Confirmation",
          "Delete Test  !!-Are you sur ?",
          { color: "orange darken-3" }
        )
      ) {
        this.data_loading = true;
        let r = await this.$maj(DELETE_TEST, { id: item.id });
        if (r) {
          this.well_change();
        }
        this.data_loading = false;
      }
    },
    async CorsOpen(item) {
      if (
        item.id == -1 &&
        this.corrosions_list.findIndex(
          (x) => x.testtype_id == item.testtype_id && x.statut_id < 6
        )
      ) {
        this.snack("Planned test is pending..");
      } else {
        this.corrosion_test = item;
        if (this.corrosion_test.id < 0) this.corrosion_test.statut_id = 5;
        this.isCorrosionClose = false;
        this.kcor++;
      }
    },
    async CementOpen(item) {
      this.cement_test = item;
      if (this.cement_test.id < 0) this.cement_test.statut_id = 5;
      this.isCementClose = false;
      this.kcem++;
    },
    init_list() {
      this.tests_list = this.well.wellstests ? this.well.wellstests : [];

      if (
        this.tests_list.filter(
          (elm) => elm.testtype_id == 2 && elm.statut_id == 6
        ).length > 0
      ) {
        this.corrosions_list = this.tests_list[0].corrosion_details;
      }
      if (
        this.tests_list.filter(
          (elm) => elm.testtype_id == 9 && elm.statut_id == 6
        ).length > 0
      ) {
        this.cements_list = this.tests_list[0].cement_details;
      }
      this.fingers_list = this.well.fingers_list ? this.well.fingers_list : [];
      this.tubulars_list = this.well.wellstubulars
        ? this.well.wellstubulars.filter((elm) => elm.active == 1)
        : [];
      this.annulus_list = this.well.wellstubulars
        ? this.well.wellstubulars.filter(
            (elm) => elm.depthfrom == 0 && elm.active == 1
          )
        : [];
      this.formations_list = this.well.wellsformations
        ? this.well.wellsformations
        : [];
      this.cas_key++;
      this.ksvg2++;
      this.apm_key++;
      this.bo_key++;
      this.fng_key++;
      this.cor_key++;
      this.cem_key++;
      this.leak_key++;
      this.xtree_key++;
      this.head_key++;
      this.down_key++;
      this.dhsv_key++;

      ////get svg
    },
    FingerOpen(item) {
      this.finger = item;
      this.isFingerClose = false;
      this.fingerkey++;
    },
    TubularSelect(item) {
      if (item) {
        this.tubular = item;
        this.tc_key++;
      }
    },
    FingerSelect(item) {
      this.finger = item;
      this.elements_list = this.finger.elements ? this.finger.elements : [];
      this.elm_key++;
    },
    TestSelect(item) {
      this.test = item;
    },

    async test_open(item) {
      this.test = item;
      if (
        item.id == -1 &&
        this.tests_list.findIndex(
          (x) => x.testtype_id == parseInt(this.tab2) && x.statut_id < 6
        ) >= 0
      ) {
        this.snack("Planned test is pending..");
      } else {
        //   if (item.id > 0) {
        if (item.id > 0) await this.get_data_test(item.id);
        this.tab_label = null;
        switch (parseInt(this.tab2)) {
          case 1:
            {
              this.tab_label = "Annuli pressure";
              this.isTestClose = false;
              this.ktest++;
            }
            break;
          case 4:
            {
              this.tab_label = "Test BO";
              this.isTestClose = false;
              this.ktest++;
            }
            break;
          case 7:
            {
              this.tab_label = "DHSV";
              this.isTestClose = false;
              this.ktest++;
            }
            break;
          case 8:
            {
              this.isTestClose = false;
              this.ktest++;
            }
            break;
          case 3:
            {
              this.tab_label = "Xtree";
              this.isTestClose = false;
              this.ktest++;
            }
            break;
          case 5:
            {
              this.tab_label = "Wellhead/Hanger";
              this.isTestClose = false;
              this.ktest++;
            }
            break;
          case 6:
            {
              this.tab_label = "Downhole";
              this.isTestClose = false;
              this.ktest++;
            }
            break;
          case 2:
            {
              this.corrosion_test = item;
              if (this.corrosion_test.id < 0) this.corrosion_test.statut_id = 5;
              this.isCorrosionClose = false;
              this.kcor++;
            }
            break;
        }
      }
      // } else {
      //   this.isAddClose = false;
      //   this.kadd++;
      // }
    },
    async get_data_test(id) {
      let r = await this.$requette(
        TESTQUERY,
        {
          TestID: parseInt(id),
          WellID: parseInt(this.well.id),
        },
        "no-cache"
      );
      if (r.ok) {
        this.founds = r.data.test_well_found;
        this.apms = r.data.test_well_apm;
        this.bo_bu = r.data.test_well_bo;
        if (r.data.test_dhsv.length > 0) this.dhsvs = r.data.test_dhsv[0];
        this.xtrees = r.data.test_well_valves;
        this.test_failures = r.data.test_failures_single;
        this.downs = r.data.test_downhead;
      }
    },
    async created() {
      let r = await this.$requette(allquery, "no-cache");
      if (r.ok) {
        this.all_failures = r.data.failures_list;
        this.barriers = r.data.wmf_list;
      }
    },
  },
};
</script>
